import axios from 'axios';
import store from '../store/index'

const PRODUCTION = true;


const baseURL_DEV = 'https://localhost:3333/';

// const baseURL_DEV = 'https://192.168.100.5:3333/';
// const baseURL_DEV = 'https://192.168.100.5:3333/';
// const baseURL_DEV = 'https://api2.cia.pizza/';
const baseURL_PRINCIPAL = 'https://api.nrt.s-pedido.com.br/';
const baseURL_BKP = 'https://bkp.nrt.s-pedido.com.br/';

if (window.location.href.indexOf('#BKP')>=0) {
  store.state.API_BKP = true;
}

console.log("CREATE AXIOS", store.state.API_BKP);
const instance = axios.create({
  baseURL: PRODUCTION ? (store.state.API_BKP?baseURL_BKP:baseURL_PRINCIPAL) : baseURL_DEV,
});
instance.cancelToken = axios.CancelToken.source

instance.interceptors.request.use(config => {

  // if (store.state.token) {
  //   axios.defaults.headers.common['Authorization'] =
  //     'Bearer ' + store.state.token;
  // }
  return config;
}, errr => {
  console.log("error requiestAAAAAAAAAAAAA", errr)
});

instance.interceptors.response.use(res => {
  // console.log('Response', res);``
  //console.log("ERROR");
  return res;       
});
instance.URLS = {
  baseURL_BKP,
  baseURL_PRINCIPAL,
  baseURL_DEV,
}
export default instance;